*,
::after,
::before {
  box-sizing: border-box;
}

/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Cabin&family=Roboto+Condensed:wght@400;700&display=swap');

html {
  font-size: 100%;
} /*16px*/

.Roboto {
  font-family: 'FontName', 'Roboto Condensed'  ;
}


